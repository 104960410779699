<template>
    <div class="row">
        <label for="subject" class="col-sm-3 col-form-label required-label">{{$t('employee.mail_subject')}}</label>
        <div class="col-sm-9">
            <input v-if="isReminder" type="text" id="subject" v-model="item.subject" />
            <input v-else type="text" id="subject" v-model="item.email_subject" />
        </div>
    </div>
    <div class="row">
        <label for="contents" class="col-sm-3 col-form-label required-label">{{$t('form.message')}}</label>
        <div class="col-sm-9">
            <textarea v-model="item.email_body" id="contents" rows="8"></textarea>
        </div>
    </div>
    <div class="row" v-if="!isReminder">
        <label for="date" class="col-sm-3 col-form-label required-label">{{$t('form.effective_date')}}</label>
        <div class="col-sm-9">
            <input type="date" id="date" v-model="item.date" />
        </div>
    </div>
</template>

<script>
    import employeeService from '@/services/EmployeeService';
    import contractService from '@/services/ContractService';
    import store from '@/store/user'
    export default {
        name: 'MailRequestForm',
        props: ['itemData'],
        data() {
            return {
                item: {
                    'email_body': '',
                    'email_subject': '',
                    'date': '',
                    'company_id': 0
                },
                isReminder: false,
                endEmployemnt: false,
                submitted: false,
                loading: false,
                forceRefresh: false,
                saveButton: null,
                service: {},
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.item = val    
            },
        },
        methods: {
            validate(){
                if(this.isReminder){
                    if(!this.item.email_body || !this.item.subject || !this.item.email_to) return false;
                }
                else{
                    if(!this.item.email_body || !this.item.email_subject || !this.item.date) return false;
                }
                this.saveButton.disabled = true;
                this.service.mail(this.item, this.item.id).then(response => {
                    this.$toast.success(this.$t('overview.email_sent'));
                    const el = document.getElementById('modal-close-button');
                    if(el) el.click();
                    if(this.forceRefresh){
                        this.$parent.$parent.getIndex();
                    }
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error)
                }).finally(() => {
                    this.saveButton.disabled = false;
                })
            },
            getData() {
                return this.item;
            },
            setData(data) {
                this.saveButton.disabled = false;
                this.isReminder = data.isReminder;
                this.endEmployemnt = data.endEmployemnt;
                this.wontBeEmployed = data.wontBeEmployed;
                this.forceRefresh = data.endEmployemnt || data.wontBeEmployed;
                this.service = this.isReminder ? contractService : employeeService;
                const id = data.contract ? data.contract.id : null;
                const employeeName = data.first_name + (data.prefix ? (' ' + data.prefix + ' ') : ' ') + data.last_name.replace('*', '');
                if(this.isReminder){
                    this.item = {
                        'email_body':`Beste ${employeeName},

Voordat we jouw salaris kunnen betalen hebben wij jouw persoonlijke gegevens nodig.
Via onderstaande knop kun je deze snel en gemakkelijk aanleveren. 
Wij gaan vertrouwelijk met de gegevens om en slaan deze alleen op in jouw personeelsdossier.
Let op: zolang jij geen gegevens hebt aangeleverd, kunnen wij jou niet verlonen.

Bedankt!`,
                        'subject': `Herinnering uitnodiging ${store.getters.getCurrentCompanyName}`,
                        'email_to': data.email,
                        'id': id
                    };
                }
                else if(this.endEmployemnt){
                    this.item = {
                        'email_body': `Beste medewerker van IBEO,\n\nWilt u ${employeeName} van ${store.getters.getCurrentCompanyName} met personeelsnummer ${data.employee_id} uit dienst melden? Alvast dank voor uw medewerking.\n\n`,
                        'email_subject': `${store.getters.getCurrentCompanyName}: uit dienst melding via IBEO HR`,
                        'company_id': store.getters.getCurrentCompanyId,
                        'date': new Date().toISOString().split('T')[0],
                        'customer_id': store.getters.getCurrentCustomerId,
                        'employee_id': data.employee_id,
                        'id': id
                    };
                }
                else if(this.wontBeEmployed){
                    this.item = {
                        'email_body': `Beste medewerker van IBEO,\n\nHier volgt een bericht dat medewerker ${employeeName} van ${store.getters.getCurrentCompanyName} toch niet in dienst zal treden.\n\n`,
                        'email_subject': `${store.getters.getCurrentCompanyName}: wijziging via IBEO HR`,
                        'company_id': store.getters.getCurrentCompanyId,
                        'date': new Date().toISOString().split('T')[0],
                        'id': id
                    };
                }
                else{
                    this.item = {
                        'email_body': `Beste medewerker van IBEO,\n\nHier volgt een bericht ter attentie van ${employeeName} (${data.email}) van ${store.getters.getCurrentCompanyName}.\n\n`,
                        'email_subject': `${store.getters.getCurrentCompanyName}: wijziging via IBEO HR`,
                        'company_id': store.getters.getCurrentCompanyId,
                        'date': new Date().toISOString().split('T')[0],
                        'id': id
                    };
                }
            },
            reset() {
                this.item = {
                    'email_body': '',
                    'email_subject': '',
                    'company_id': 0,
                    'date': '',
                    'id': null
                }
                this.loading = false
                this.invalid = {}
            }
        },
        mounted () {
            this.saveButton = document.getElementById('savebutton');
            this.saveButton.innerHTML = this.$t('form.send');
        }
    }
</script>
